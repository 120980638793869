@use 'styles/_variables.scss' as *;
.productItem {
  align-self: flex-end;
  border-bottom: none;
  margin-bottom: 0;
  padding: ($global-padding * 3) 0;

  &__cover {
    position: relative;
    padding: 0;
    overflow: hidden;

    @media (min-width: $m-screen) {
      max-width: 128px;
    }

    &__image {
      max-width: 100%;
    }
  }

  &__noCover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    color: #aeb6c2;
    height: 10rem;
    width: 150px;
    border: 1px solid #d1d6e0;
    margin: 0 2rem $global-margin 0;
    font-family: $brand-font-family;
  }

  &__title {
    font-family: $brand-font-family;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &__adultsOnly {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 3.5rem solid transparent;
    border-left: 3.5rem solid $white;
    transform: translateY(-50%);

    &:after {
      content: "";
      display: block;
      height: 100px;
      width: 1px;
      transform: rotate(45deg);
      background-color: $pale-gray-4;
      position: absolute;
      left: -30px;
      bottom: -80px;
    }

    i {
      position: absolute;
      left: -3.25rem;
      top: 0.25rem;
      font-size: 1.5rem;
      color: $almost-black-60;
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: $almost-black-60;
    margin: 0;

    @media (max-width: $s-screen) {
      font-size: $small-font-size;
    }

    i {
      margin-right: $global-margin * 0.5;
    }

    a {
      margin-left: $global-margin * 0.5;
    }
  }

  &__price {
    font-family: $brand-font-family;
  }
}
