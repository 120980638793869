@use 'styles/_variables.scss' as *;
.author {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $almost-black-60;
  margin: 0;

  @media (max-width: $s-screen) {
    font-size: $small-font-size;
  }

  i {
    margin-right: $global-margin * 0.5;
  }

  a {
    margin-left: $global-margin * 0.5;
  }
}
