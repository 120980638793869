@use 'styles/_variables.scss' as *;
.authorSpotlight {
  &__showMore {
    padding-bottom: $global-margin * 3;
    display: flex;
    justify-content: center;
  }

  &__about {
    color: $almost-black-60;
    line-height: 1.5;
    padding: ($global-padding * 3) 0;
    align-items: center;
  }

  &__websiteLink {
    padding-top: $global-margin * 2;
    font-family: $brand-font-family;
  }

  &__title {
    margin-bottom: 0;
  }

  &__links {
    margin-bottom: $global-margin * 0.5;

    @media (min-width: $m-big-screen) {
      padding-top: $global-padding * 1.5;
    }
  }

  &__linkContainer {
    margin-top: $global-margin;
  }

  &__social {
    display: flex;
    align-items: center;
    margin-top: $global-margin;

    a,
    span {
      text-decoration: none;
      margin-right: $global-margin;

      i::before {
        font-size: $base-font-size * 1.5;
      }
    }
  }

  &__social,
  &__links {
    @media (max-width: $m-big-screen) and (min-width: $s-screen) {
      display: inline-block;
    }
  }

  &__publications {
    background-color: $pale-gray-2;
    padding-top: $global-padding * 6.375;

    h3 {
      margin-top: 0;
    }

    .sortBar__dropdown__title {
      color: $indigo !important;
      font-weight: bold;
      background-color: $pale-gray-4;
      padding: ($global-padding * 0.75) $global-padding;

      i {
        margin-left: 1rem;
      }
    }

    .sortBar__dropdown__option {
      color: $indigo;
      display: block;
      padding: 0 1rem;
      cursor: pointer;
    }

    .menuItem__dropdownMenu {
      top: 3rem !important;
    }
  }

  &__publicationsHeader {
    @media (max-width: $m-screen) {
      text-align: center;
    }
  }

  &__publicationsGrid {
    align-items: baseline;
  }

  .productsPagination {
    border-top: none;
  }

  &noProducts {
    padding: ($global-padding * 6.375) 0;
  }
}

.authorProfileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 8rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;

  @media (max-width: $m-big-screen) {
    width: 4rem;
    height: 4rem;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.sortBar__dropdown {
  font-family: $brand-font-family;
  position: relative;

  .menuItem__dropdownMenu {
    display: none;
    position: absolute;
    min-width: 150px;
    background-color: #fff;
    border: 1px solid #c0c6d1;
    border-radius: 0.125rem;
    box-shadow: 4px 8px 8px 0 rgb(0 0 0 / 20%);
    line-height: 1.5;
    padding: 1rem 0;
    z-index: 2;

    ::before,
    ::after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 9px;
      margin-left: -9px;
    }

    ::before {
      border-bottom-color: #c0c6d1;
    }

    ::after {
      border-bottom-color: #fff;
    }
  }

  &:hover {
    * {
      text-decoration: none;
    }

    .menuItem__dropdownMenu {
      display: block;
      top: 3rem;
      font-weight: 300;

      a {
        line-height: 1;

        &:not(:last-of-type) {
          margin-bottom: $global-margin * 0.5;
        }
      }
    }
  }

  font-weight: bold;

  span {
    font-weight: 300;
    color: $almost-black-60;
    margin-right: $global-margin * 0.5;
  }

  &__title {
    display: flex;
    align-items: center;
    line-height: 1.5;
  }
}
