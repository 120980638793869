@use 'styles/_variables.scss' as *;
.promotion {
  display: flex;
  align-items: center;
}

.previous {
  margin: 0 $global-padding * 0.5;
  opacity: 0.6;
  color: $almost-black;
  text-decoration: line-through;
}
